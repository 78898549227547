<template>
  <ion-item v-if="club">
    <ion-thumbnail slot="start">
      <img :src="club.branding.logoUrl" />
    </ion-thumbnail>
    <ion-label>
      <h4>
        <strong>{{ club.name }}</strong>
      </h4>
      <h6>{{ club.address.city }}</h6>
      <p class="capitalize">{{ club.sport }}</p>
<!--       <ion-icon :icon="newspaper" :class="{ grey: !club.mediaChannel.hasWordpress }"></ion-icon>
      <ion-icon :icon="logoTwitter" :class="{ grey: !club.mediaChannel.hasTwitter }"></ion-icon>
      <ion-icon :icon="logoFacebook" :class="{ grey: !club.mediaChannel.hasFacebook }"></ion-icon>
      <ion-icon :icon="logoInstagram" :class="{ grey: !club.mediaChannel.hasInstagram }"></ion-icon> -->
    </ion-label>

    <router-link v-if="isBookmarked" class="padding-div" :to="`/clubs/${club.uuid}`">
      <ion-icon slot="end" :icon="settings"></ion-icon>
    </router-link>
    <router-link v-else class="padding-div" :to="`#`">
      <ion-icon slot="end" :icon="informationCircleOutline"></ion-icon>
    </router-link>

    <ion-icon v-if="isBookmarked" slot="end" :icon="bookmark" @click="bookmarkToggle()"></ion-icon>
    <ion-icon v-else slot="end" :icon="bookmarkOutline" @click="bookmarkToggle()"></ion-icon>
  </ion-item>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { IonThumbnail, IonIcon, IonItem, IonLabel } from "@ionic/vue";
import {
  bookmark,
  bookmarkOutline,
  informationCircleOutline,
  logoFacebook,
  logoInstagram,
  logoTwitter,
  newspaper,
  settings,
} from "ionicons/icons";
import { Club } from '@/types/Club/Club';

export default defineComponent({
  name: "ClubListItem",
  props: {
    club: {
      type: Object as PropType<Club>,
    }
  },
  components: {
    IonThumbnail,
    IonIcon,
    IonItem,
    IonLabel,
  },
  computed: {
    isBookmarked(): any {
      return this.$store.getters.isClubSubscribed(this.club);
    },
  },
  methods: {
    bookmarkToggle() {
      if (this.isBookmarked) {
        this.$store.dispatch("deleteClubSubscription", this.club);
      } else {
        this.$store.dispatch("createClubSubscription", this.club)
      }
      this.$store.dispatch("news")
    },
  },
  setup() {
    return {
      bookmark,
      bookmarkOutline,
      informationCircleOutline,
      logoFacebook,
      logoInstagram,
      logoTwitter,
      newspaper,
      settings,
    };
  },
});
</script>

<style scoped>
ion-label h4 {
  color: var(--ion-color-primary);
}

ion-icon {
  color: var(--ion-color-primary);
}

ion-label ion-icon {
  margin: 0.25rem;
}

ion-icon.grey {
  color: #cccccc;
}
.capitalize {
  text-transform: capitalize;
}
.padding-div:focus {
  outline: 0;
}

</style>
