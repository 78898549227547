
import {
  IonInput,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";

import { defineComponent } from 'vue';
export default defineComponent({
  name: "SearchInput",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonInput,
    IonLabel,
  },
});
