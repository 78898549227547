<template>
  <base-menu-layout pageTitle="Vereine">
    <!-- Default Segment -->
    <ion-segment @ionChange="segmentChanged($event)" value="clubSearch" slot="fixed">
      <ion-segment-button value="clubSearch">
        <ion-label>Alle</ion-label>
      </ion-segment-button>
      <ion-segment-button value="myClubs">
        <ion-label>Abonniert</ion-label>
      </ion-segment-button>
      <ion-segment-button value="userStories">
        <ion-label>Stories</ion-label>
      </ion-segment-button>
    </ion-segment>

    <!-- -- -- -- -- -- -- -- -- -- -- -- -- -- 
       All Clubs
    -- -- -- -- -- -- -- -- -- -- -- -- ---->

    <div v-if="segmentState == 'clubSearch'" class="offsetTop">
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <h4>Verfügbare Vereine und Verbände</h4>
          </ion-col>
        </ion-row>
      </ion-grid>
      <club-list :search="false"/>
    </div>

    <!-- -- -- -- -- -- -- -- -- -- -- -- -- -- 
       Favorited Clubs
    -- -- -- -- -- -- -- -- -- -- -- -- ---->

    <div v-if="segmentState == 'myClubs'" class="offsetTop">
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <h4>Abonnierte Vereine</h4>
          </ion-col>
        </ion-row>
      </ion-grid>
      <club-list :search="false" :filter="'subscribedOnly'" />
    </div>

    <!-- -- -- -- -- -- -- -- -- -- -- -- -- -- 
       User Stories
    -- -- -- -- -- -- -- -- -- -- -- -- ---->

    <div v-if="segmentState == 'userStories'" class="offsetTop">
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <h4>User Stories</h4>
          </ion-col>
          <ul>
            <li
              class="green"
            >Ich als Nutzer möchte eine Liste der freigeschalteten / verfügbaren Clubs sehen</li>
            <li
              class="green"
            >Ich als Nutzer möchte alle meine "abbonierten" Clubs oder Webseiten sehen.</li>
            <li class="green">Ich als Nutzer möchte einen neuen Club abonnieren können.</li>
            <li>Ich als Nutzer möchte mich über einen Club informieren können.</li>
            <li>Ich als Nutzer möchte einen QR-Code eines Vereins einscannen können (und diesen Club fortan abonnieren).</li>
            <li
              class="green"
            >Ich als Nutzer möchte einen Club, den ich abonniert habe, abbestellen können.</li>
            <li>Ich als Nutzer möchte für einen Club, den ich abonniert habe, Benachrichtigungen einstellen können.</li>
            <li>Ich als Nutzer möchte für einen Club, den ich abonniert habe, Kategorien (Facebook, Twitter, Instagram, Webseite (mit Categorien)) einsehen können einstellen können.</li>
          </ul>
        </ion-row>
      </ion-grid>
    </div>
  </base-menu-layout>
</template>

<script lang="ts">
import {
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";
import BaseMenuLayout from "../../../components/base/BaseMenuLayout.vue";
import ClubList from "./components/ClubList.vue";
import {
  add,
  bookmark,
  bookmarkOutline,
  informationCircle,
  heart,
  logoFacebook,
  logoInstagram,
  logoTwitter,
  newspaper,
  qrCode,
  save,
  settings,
} from "ionicons/icons";

import { defineComponent } from 'vue';
import { useStore } from "vuex";

export default defineComponent({
  components: {
    BaseMenuLayout,
    ClubList,
    IonCol,
    IonGrid,
    IonLabel,
    IonRow,
    IonSegment,
    IonSegmentButton
  },
  methods: {
    segmentChanged(ev: any) {
      this.segmentState = ev.detail.value;
    },
  },
  data: function() {
    return {
      segmentState: "clubSearch",
    };
  },
  setup() {
    const store = useStore()

    store.dispatch("clubs");
    store.dispatch("clubSubscriptions")

    return {
      add,
      bookmark,
      bookmarkOutline,
      informationCircle,
      heart,
      logoFacebook,
      logoInstagram,
      logoTwitter,
      newspaper,
      qrCode,
      save,
      settings,
    };
  },
});
</script>

<style scoped>
ion-segment {
  background-color: var(--ion-color-primary-contrast);
  border-bottom: grey 1px solid;
}

ion-segment ion-label {
  color: var(--ion-color-primary);
}

ion-icon {
  color: var(--ion-color-primary);
}

ion-label ion-icon {
  margin: 0.25rem;
}

ion-label h4 {
  color: var(--ion-color-primary);
}

ion-icon.grey {
  color: grey;
}

li.green {
  color: green;
}

div.offsetTop {
  margin-top: 36px;
}
</style>