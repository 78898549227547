<template>
  <ion-grid>
    <ion-row class="ion-align-items-center">
      <ion-col size="12">
        <h4>Verein suchen</h4>
      </ion-col>
      <ion-col size="12">
        <ion-item>
          <ion-label position="floating">Gib hier den Vereinsnamen ein...</ion-label>
          <ion-input></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script lang="ts">
import {
  IonInput,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";

import { defineComponent } from 'vue';
export default defineComponent({
  name: "SearchInput",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonInput,
    IonLabel,
  },
});
</script>
