
import { defineComponent, PropType } from 'vue';
import { IonThumbnail, IonIcon, IonItem, IonLabel } from "@ionic/vue";
import {
  bookmark,
  bookmarkOutline,
  informationCircleOutline,
  logoFacebook,
  logoInstagram,
  logoTwitter,
  newspaper,
  settings,
} from "ionicons/icons";
import { Club } from '@/types/Club/Club';

export default defineComponent({
  name: "ClubListItem",
  props: {
    club: {
      type: Object as PropType<Club>,
    }
  },
  components: {
    IonThumbnail,
    IonIcon,
    IonItem,
    IonLabel,
  },
  computed: {
    isBookmarked(): any {
      return this.$store.getters.isClubSubscribed(this.club);
    },
  },
  methods: {
    bookmarkToggle() {
      if (this.isBookmarked) {
        this.$store.dispatch("deleteClubSubscription", this.club);
      } else {
        this.$store.dispatch("createClubSubscription", this.club)
      }
      this.$store.dispatch("news")
    },
  },
  setup() {
    return {
      bookmark,
      bookmarkOutline,
      informationCircleOutline,
      logoFacebook,
      logoInstagram,
      logoTwitter,
      newspaper,
      settings,
    };
  },
});
