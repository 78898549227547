
import { defineComponent, computed } from 'vue';
import { IonList } from "@ionic/vue";
import SearchInput from "./SearchInput.vue";
import ClubListItem from "./ClubListItem.vue";

import { useStore } from "vuex";


export default defineComponent({
  name: "ClubList",
  props: {
        filter: String,
        search: Boolean
    },
  components: {
    ClubListItem,
    IonList,
    SearchInput
  },
  setup(props) {
    const store = useStore();
    const clubs = computed(() => {
      switch (props.filter) {
        case 'subscribedOnly':
          return store.getters.subscribedClubs;
        default:
          return store.getters.clubs;
      }
    });
    const hasSearch = computed(() => props.search)
    return {
      clubs,
      SearchInput,
      hasSearch
    }
  }
});
