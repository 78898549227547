
import {
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";
import BaseMenuLayout from "../../../components/base/BaseMenuLayout.vue";
import ClubList from "./components/ClubList.vue";
import {
  add,
  bookmark,
  bookmarkOutline,
  informationCircle,
  heart,
  logoFacebook,
  logoInstagram,
  logoTwitter,
  newspaper,
  qrCode,
  save,
  settings,
} from "ionicons/icons";

import { defineComponent } from 'vue';
import { useStore } from "vuex";

export default defineComponent({
  components: {
    BaseMenuLayout,
    ClubList,
    IonCol,
    IonGrid,
    IonLabel,
    IonRow,
    IonSegment,
    IonSegmentButton
  },
  methods: {
    segmentChanged(ev: any) {
      this.segmentState = ev.detail.value;
    },
  },
  data: function() {
    return {
      segmentState: "clubSearch",
    };
  },
  setup() {
    const store = useStore()

    store.dispatch("clubs");
    store.dispatch("clubSubscriptions")

    return {
      add,
      bookmark,
      bookmarkOutline,
      informationCircle,
      heart,
      logoFacebook,
      logoInstagram,
      logoTwitter,
      newspaper,
      qrCode,
      save,
      settings,
    };
  },
});
